.wrapper {
  color: var(--color_light);
  font-weight: lighter;
  font-size: 1.3rem;
  text-align: center;
  padding-bottom: 60px;
}
.line_wrapper {
  margin-bottom: 30px;
}
.line {
  margin: 0 20px;
  width: 60px;
  height: 3px;
  background: var(--color_orange);
}

.circle {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background: var(--color_orange);
}

.title {
  display: block;
  font-weight: 400;
  color: var(--color_light);
  font-size: 1.6rem;
  letter-spacing: 13px;
  padding-bottom: 20px;
}

@media screen and (max-width: 767px) {
  .title {
    font-size: 1.3rem !important;
    letter-spacing: 10px !important;
  }
}
