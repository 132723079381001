.item {
  display: block;
  height: 40px;
  width: 40px;
  margin: 2px;
}

.tooltip {
  background: var(--color_dark) !important;
  color: var(--color_orange) !important;
  border: 1px solid var(--color_light) !important;
}

.title {
  background: var(--color_dark) !important;
  color: var(--color_light) !important;
  font-weight: 500;
  font-size: 16px;
  border-bottom: none;
}

.bold {
  font-weight: 700;
}

.github {
  font-size: 30px;
}

.date {
  font-size: 16px;
}

.text {
  color: var(--color_light) !important;
  font-weight: lighter;
}

.lv0 {
  background: var(--color_dark);
}
.lv1 {
  background: var(--color_orange);
  opacity: 0.15;
}

.lv2 {
  background: var(--color_orange);
  opacity: 0.3;
}

.lv3 {
  background: var(--color_orange);
  opacity: 0.5;
}

.lv4 {
  background: var(--color_orange);
  opacity: 0.7;
}

.lv5 {
  background: var(--color_orange);
  opacity: 1;
}

.lv1,
.lv2,
.lv3,
.lv4,
.lv5 {
  transition: 0.3s all ease-in-out;
}

.lv1:hover,
.lv2:hover,
.lv3:hover,
.lv4:hover,
.lv5:hover {
  background: var(--color_light);
  border-radius: 50%;
  transition: 0.3s all ease-in-out;
  cursor: pointer;
}
@media screen and (max-width: 500px) {
  .lv0 {
    opacity: 0.5;
  }
}
