.wrapper {
  position: fixed;
  top: 0;
  left: -110%;
  width: 100%;
  height: 100%;
  background: var(--color_orange);
  z-index: 999;
  clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);

  transition: 0.5s all ease-in-out;
}

.wrapper.active {
  left: 0 !important;
}

.close__btn {
  position: absolute;
  top: 20px;
  right: 30px;
  z-index: 1000;
  color: var(--color_light) !important;
  font-size: 36px;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
}

.close__btn:hover {
  color: var(--color_dark) !important;
  transition: 0.3s all ease-in-out;
}

.circle {
  position: absolute;
  border-radius: 50%;
  background: var(--color_dark);
  animation: ripple 15s infinite;
  box-shadow: 0px 0px 1px 0px var(--color_dark) !important;
}

.small {
  width: 200px;
  height: 200px;
  left: -100px;
  bottom: -100px;
}

.medium {
  width: 400px;
  height: 400px;
  left: -200px;
  bottom: -200px;
}

.large {
  width: 600px;
  height: 600px;
  left: -300px;
  bottom: -300px;
}

.xlarge {
  width: 1000px;
  height: 1000px;
  left: -500px;
  bottom: -500px;
}

.xxlarge {
  width: 1400px;
  height: 1400px;
  left: -700px;
  bottom: -700px;
}

.shade1 {
  opacity: 0.2;
}
.shade2 {
  opacity: 0.5;
}

.shade3 {
  opacity: 0.6;
}

.shade4 {
  opacity: 0.7;
}

.shade5 {
  opacity: 1;
}

@keyframes ripple {
  0% {
    transform: scale(0.8);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(0.8);
  }
}
