.hero {
  color: var(--color_light) !important;
  min-height: 400px;
  position: relative;
  z-index: 2;
}

.box {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 180px;
  height: 180px;
  background: var(--color_orange);
}

.container {
  padding: 90px 90px;
  position: relative;
  z-index: 3;
  display: flex;
}

.hero__left {
  height: 525px;
  width: 300px;
  border: 3px solid var(--color_light);
}
.hero__right {
  padding-top: 53px;
}

.name {
  display: block;
  font-size: 5.2rem;
  color: var(--color_orange);
  background: var(--color_dark);
  transform: translateX(-44%) !important;
  letter-spacing: 5px;
  line-height: 4.9rem;
  padding-bottom: 15px;
  padding-top: 25px;
}

.title {
  display: block;
  font-size: 1.6rem;
  color: var(--color_light);
  background: var(--color_dark);
  transform: translateX(-195px) !important;
  letter-spacing: 13px;
  padding-bottom: 50px;
}

@media screen and (max-width: 1220px) {
  .name {
    font-size: 4rem;
    transform: translateX(-56%) !important;
  }
}

@media screen and (max-width: 1096px) {
  .name {
    font-size: 3.6rem;
    transform: translateX(-40%) !important;
  }
  .title {
    transform: translateX(-40%) !important;
  }
}
@media screen and (max-width: 950px) {
  .name {
    position: relative;
    z-index: 6;
    font-size: 3.6rem;
    background: linear-gradient(
      90deg,
      var(--color_dark) 0%,
      var(--color_dark) 50%,
      transparent 50%
    );
    transform: translateX(-50%) !important;
  }
  .title {
    position: relative;
    z-index: 6;
    background: linear-gradient(
      90deg,
      var(--color_dark) 0%,
      var(--color_dark) 50%,
      transparent 50%
    );
    transform: translateX(-50%) !important;
  }
}
@media screen and (max-width: 790px) {
  .hero__right {
    width: 100%;
  }
  .name {
    font-size: 3.6rem;
    transform: translateX(-20%) !important;
  }
  .title {
    transform: translateX(-20%) !important;
  }
}

@media screen and (max-width: 580px) {
  .hero__right {
    width: 100%;
  }
  .name {
    position: relative;
    z-index: 6;
    font-size: 3.6rem;
    background: var(--color_dark);
    transform: translateX(-5%) !important;
  }
  .title {
    position: relative;
    z-index: 6;
    background: var(--color_dark);
    transform: translateX(-5%) !important;
  }
  .hero__left {
    display: none;
  }
}

@media screen and (max-width: 350px) {
  .name {
    font-size: 2.5rem;
  }
  .title {
    font-size: 1.4rem;
  }
}
