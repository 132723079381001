.project__card {
  width: 100%;
  border-bottom: 1px dashed var(--color_orange);
}

.img {
  width: 50px !important;
  height: auto !important;
}

.crd {
  width: 100%;
  padding: 5px 0;
  color: var(--color_light);
  background: var(--color_dark);
}

.title {
  color: var(--color_orange);
  font-weight: 300 !important;
  padding-bottom: 3px;
}

.desc {
  color: var(--color_light);
  font-size: 16px;
}

.tag__section {
  display: flex;
  padding-bottom: 5px;
  flex-wrap: wrap;
}

.tag {
  margin-right: 3px;
  margin-bottom: 3px;
  background: var(--color_orange);
  font-size: 14px;
  color: var(--color_dark);
  font-weight: normal;
  padding: 2px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: 0.3s all ease-in-out;
}

.tag:hover {
  background: var(--color_light);
  color: var(--color_dark);
  transition: 0.3s all ease-in-out;
}

.icon {
  color: var(--color_light);
  margin-right: 10px;
  transition: 0.3s all ease-in-out;
}

.icon:hover {
  color: var(--color_orange);
  transition: 0.3s all ease-in-out;
}

.btn__read {
  margin-top: 8px;
  background: var(--color_dark) !important;
  border: 1px solid var(--color_light);
  font-size: 18px;
  color: var(--color_light) !important;
}

@media screen and (max-width: 768px) {
  .project__card {
    width: 90% !important;
  }
}
