.item {
  display: block;
  color: var(--color_light);
  padding-bottom: 10px;
}

.institution {
  color: var(--color_orange);
  /* cursor: pointer; */
  transition: 1s all ease-in-out;
}

.institution:hover {
  color: var(--color_light);
  text-decoration: none !important;
  transition: 1s all ease-in-out;
}

.time {
  font-size: 1rem;
  padding-left: 15px;
}

.degree {
  font-size: 1.2rem;
}
