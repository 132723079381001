.wrapper {
  position: absolute;
  top: 10px;
  z-index: 999;
  left: 50%;
  transform: translateX(-50%);
}

.icon_section {
  color: var(--color_orange);
  font-size: 36px;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
}

.icon {
  color: var(--color_orange);
  transition: 0.3s all ease-in-out;
}

.icon:hover {
  color: var(--color_light);
  transition: 0.3s all ease-in-out;
}

.title {
  color: var(--color_light);
}

.close {
  color: var(--color_light);
  font-size: 24px;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
}

.close:hover {
  color: var(--color_orange);
  transition: 0.3s all ease-in-out;
}

.picker {
  width: 200px;
  padding: 20px;
  background: var(--color_dark);
  border: 1px solid var(--color_orange);
}

@media screen and (max-width: 767px) {
  .wrapper {
    background: var(--color_dark);
    padding: 10px 5px;
    padding-bottom: 20px;
    left: auto;
    right: 10px;
    transform: none;
    border: 2px solid var(--color_orange);
  }
}
