:root {
  --color_orange: #ef5124;
  --color_dark: #28292d;
  --color_light: #ebe9e9;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.swiper {
  overflow-y: visible !important;
}

.swiper-3d .swiper-slide-shadow-left,
.swiper-slide-shadow-right {
  background-image: none !important;
}

.link_text {
  text-decoration: none !important;
  color: var(--color_orange) !important;
}

.link_text:hover {
  color: var(--color_light) !important;
}

.min_height {
  min-height: calc(100vh - 100px);
}

.text_light {
  color: var(--color_light) !important;
}

.text_primary {
  color: var(--color_orange) !important;
}
