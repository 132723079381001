.title {
  padding-top: 10px;
  color: var(--color_light);
  font-weight: lighter;
}

.line {
  width: 100%;
  height: 1px;
  background: var(--color_orange);
}

.desc {
  color: var(--color_light);
  text-align: center;
  font-size: 16px;
}
