.item {
  border: 1px dashed var(--color_orange) !important;
  color: var(--color_light) !important;
  padding-top: 20px;
  animation: anim 1s ease-in-out 1;
  margin-top: 30px;
  min-height: 510px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.bottom {
  width: 300px;
  margin-top: auto !important;
  padding: 10px 20px;
  background: var(--color_orange) !important;
}
.name {
  font-size: 24px;
  font-weight: normal;
}

.quote {
  font-size: 32px;
  color: var(--color_orange);
}

.quote__right {
  text-align: end !important;
}

.content {
  font-size: 18px;
  font-weight: lighter;
}

.content__area {
  min-height: 150px;
}
