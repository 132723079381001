.row {
  display: flex;
}

.stats {
  display: block;
  transform: translateY(-80px);
}

.loading {
  margin: auto auto;
}

@media screen and (max-width: 1080px) {
  .wrapper {
    position: absolute;
    z-index: 5;
    right: 5%;
  }
}
@media screen and (max-width: 350px) {
  .wrapper {
    position: absolute;
    z-index: 5;
    right: 0;
  }
}
