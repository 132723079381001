.socialIcons {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: column;
  padding-left: 15px;
}

.socialIcons a {
  text-decoration: none;
  font-size: 1.6rem;
  color: var(--color_orange);
  transition: 0.3s all ease;
}

.socialIcons a:hover {
  text-decoration: none;
  color: var(--color_light);
  transition: 0.3s all ease;
}
@media screen and (max-width: 740px) {
  .socialIcons {
    flex-direction: row;
    padding-top: 50%;
  }
}
@media screen and (max-width: 707px) {
  .socialIcons {
    flex-direction: row;
    padding-top: 30%;
  }
}
@media screen and (max-width: 580px) {
  .socialIcons {
    flex-direction: row;
    padding-top: 50%;
  }
}
@media screen and (max-width: 350px) {
  .socialIcons {
    flex-direction: row;
    padding-top: 80%;
  }
  .socialIcons a {
    padding-right: 10px;
  }
}
